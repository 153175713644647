import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Applogo } from "../../../Routes/ImagePath";
import { VerifyOtp } from "../../../Redux/services/User";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

const Otp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleSubmit } = useForm({});
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const loginUserId = useSelector(state => state?.user?.logUserID) || localStorage.getItem('loginUserId');
  const inputRefs = useRef([]);
  const handleChange = (value, index) => {
    if (/^\d?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };
  const onSubmit = async () => {
    const otpCode = otp.join("");
    try {
      const response = await dispatch(VerifyOtp({ id: loginUserId, otp: otpCode }));
      if (response?.payload?.status && (response?.payload?.data.role?.name === "Employee" || response?.payload?.data.role?.name === "Internship")) {
        navigate("/employee-dashboard");
      } else {
        navigate("/admin-dashboard");
      }

    } catch (error) {
      console.error("Error during OTP verification:", error);
    }
  };

  return (
    <div className="account-page">
      <div className="main-wrapper">
        <div className="account-content">
          <div className="container">
            <div className="account-logo">
              <Link to="/admin-dashboard">
                <img src={Applogo} alt="Dreamguy's Technologies" />
              </Link>
            </div>
            <div className="account-box">
              <div className="account-wrapper">
                <h3 className="account-title">OTP</h3>
                <p className="account-subtitle">Verify your account</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="otp-wrap">
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        type="text"
                        value={digit}
                        placeholder="0"
                        onChange={(e) => handleChange(e.target.value, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        maxLength={1}
                        className="otp-input"
                        ref={(el) => (inputRefs.current[index] = el)}
                      />
                    ))}
                  </div>
                  <div className="input-block mb-4 text-center">
                    <button className="btn btn-primary account-btn" type="submit">
                      Enter
                    </button>
                  </div>
                  <div className="account-footer">
                    <p>
                      Not yet received? <Link to="#">Resend OTP</Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Otp;
