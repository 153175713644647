import { createSlice } from "@reduxjs/toolkit";
import { Add_employee, edit_employee_data, get_employee_data, get_my_profile } from "../services/Employee";
const initialState = {
    isLoading: false,
    error: null,
    token: localStorage.getItem('token'),
    errorMsg: '',
    profileData: {},
    employeeData: [],
    EditEmployeeList: []
};
export const employeeSlice = createSlice({
    name: "employee",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        // ========== Add_employee ============== //
        builder.addCase(Add_employee.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Add_employee.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.errorMsg = action?.payload;
            state.logUserID = action?.payload?.data?.data?.id
        });
        builder.addCase(Add_employee.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
            state.errorMsg = action?.payload;
        });
        // ===========get employee data ============== //
        builder.addCase(get_employee_data.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_employee_data.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.employeeData = action?.payload?.data
        });
        builder.addCase(get_employee_data.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get edit historian data ============== //
        builder.addCase(edit_employee_data.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(edit_employee_data.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.EditEmployeeList = action?.payload?.data
        });
        builder.addCase(edit_employee_data.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });

        // ==========get my profile data ============== //
        builder.addCase(get_my_profile.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_my_profile.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.profileData = action?.payload?.data
        });
        builder.addCase(get_my_profile.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
    }

});
export default employeeSlice.reducer;
