import { createSlice } from "@reduxjs/toolkit";
import { login, VerifyOtp, forgotPassword, resetPassword } from "../services/User";
const initialState = {
  // token: JSON.parse(localStorage.getItem("token")) || null,
  isLoading: false,
  error: null,
  token: localStorage.getItem('token'),
  logUserID: '',
  errorMsg: '',
  value: { email: "", password: "", header: false }
};
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // login: (state, { payload }) => {
    //   state.value = payload;
    // },
    logout: (state) => {
      state.value = { email: "", password: "" };
    },
    setLayout: (state, { payload }) => {
      state.layout = payload;
    },
    setToogleHeader: (state, { payload }) => {
      state.header = payload;
    },

  },
  extraReducers: (builder) => {
    // ========== Login ============== //
    builder.addCase(login.pending, (state) => {
      console.log('login-panding', state);
      state.isLoading = true;
      state.error = false;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = false;
      state.errorMsg = action?.payload;
      state.logUserID = action?.payload?.data?.data?.id
    });
    builder.addCase(login.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action?.payload;
      state.errorMsg = action?.payload;
    });

    // ========== Verify Otp ============== //
    builder.addCase(VerifyOtp.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(VerifyOtp.fulfilled, (state, action) => {
      state.isLoading = false;
      state.token = action.payload.token;
      state.token = action?.payload?.data?.token;
      localStorage.setItem('role', action.payload?.data?.role?.name)
    });

    builder.addCase(VerifyOtp.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || 'OTP verification failed';
    });

    // ========== Forgot Password ============== //
    builder.addCase(forgotPassword.pending, (state) => {
      state.isLoading = true;
      state.error = false;
    });
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = false;
      state.token = action?.payload?.data?.token;
    });
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action?.payload;
    });

    // ========== Reset Password ============== //
    builder.addCase(resetPassword.pending, (state) => {
      state.isLoading = true;
      state.error = false;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = false;
      state.token = action?.payload?.data?.token;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action?.payload;
    });
  }

});

export const { setLayout, setToogleHeader } = userSlice.actions;
export default userSlice.reducer;
